import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, NavLink } from 'react-router-dom';
import { Flipbook } from './flipbook-component';
import { ContactForm } from './contact-form';
import { Menu, X, Globe2, DollarSign, ArrowRight, Database } from 'lucide-react';
import './App.css';

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    // Prevent body scroll when menu is open
    document.body.style.overflow = !isMenuOpen ? 'hidden' : 'unset';
  };

  return (
    <Router>
      <div className="min-h-screen bg-gray-50">
        <header className="fixed top-0 left-0 right-0 bg-white shadow-sm z-50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="flex justify-between items-center h-16">
              <Link to="/" className="flex-shrink-0">
                <img
                  src="https://mondonomo.ai/static/img/10jezika.svg"
                  alt="Mondonomo"
                  className="h-8 w-auto"
                />
              </Link>

              {/* Mobile menu button */}
              <button
                onClick={toggleMenu}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:text-gray-900 hover:bg-gray-100 md:hidden"
                aria-label="Toggle menu"
              >
                {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>

              {/* Desktop navigation */}
              <nav className="hidden md:flex md:items-center md:space-x-6">
                <NavLink to="/" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">
                  Pitch Deck
                </NavLink>
                <NavLink to="/sales-pitch" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">
                  Sales Pitch
                </NavLink>
                <a
                  href="https://mondonomo.ai"
                  className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Website
                </a>
                <NavLink to="/contact" className="bg-emerald-600 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-emerald-700">
                  Contact
                </NavLink>
              </nav>
            </div>
          </div>

          {/* Mobile menu */}
          <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" onClick={toggleMenu}></div>
            <nav className="fixed right-0 top-0 bottom-0 w-64 bg-white shadow-xl flex flex-col">
              <div className="flex justify-end p-4">
                <button onClick={toggleMenu} className="text-gray-500 hover:text-gray-700">
                  <X size={24} />
                </button>
              </div>
              <div className="px-4 py-2 space-y-1">
                <NavLink
                  to="/"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                  onClick={toggleMenu}
                >
                  Pitch Deck
                </NavLink>
                <NavLink
                  to="/sales-pitch"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                  onClick={toggleMenu}
                >
                  Sales Pitch
                </NavLink>
                <a
                  href="https://mondonomo.ai"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={toggleMenu}
                >
                  Website
                </a>
                <NavLink
                  to="/contact"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                  onClick={toggleMenu}
                >
                  Contact
                </NavLink>
              </div>
            </nav>
          </div>
        </header>

        <main className="pt-16">
          <Routes>
            <Route path="/" element={<PitchDeckView />} />
            <Route path="/sales-pitch" element={<SalesPitch />} />
            <Route path="/contact" element={<ContactForm />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
};

const PitchDeckView = () => {
  const [showPitchDeck, setShowPitchDeck] = useState(false);

  if (showPitchDeck) {
    return (
      <div className="w-full h-full min-h-screen bg-gray-100 p-4">
        <div className="w-full h-full">
          <Flipbook pdfUrl="/pitch.pdf" />
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto px-4 py-8 mt-4 sm:mt-8">
      <div className="text-center mb-12">
        <h1 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-4 px-4">
          Making Global Names Work for Business
        </h1>
        <p className="text-lg sm:text-xl text-gray-600 mb-8 px-4">
          Bringing the World Together Through Names
        </p>
        <button
          onClick={() => setShowPitchDeck(true)}
          className="bg-emerald-600 text-white px-6 py-3 rounded-lg hover:bg-emerald-700 transition duration-200 mb-8"
        >
          View Full Pitch Deck
        </button>
      </div>

      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6 px-4">
        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
          <div className="flex items-center mb-4">
            <Globe2 className="w-6 h-6 text-emerald-600 mr-3 flex-shrink-0" />
            <h3 className="text-lg font-semibold text-gray-900">Global Challenge</h3>
          </div>
          <p className="text-gray-600 mb-4">
            95% of the global population don't have English names, yet most systems are English-centric.
          </p>
          <a
            href="https://thai.mondonomo.ai"
            target="_blank"
            rel="noopener noreferrer"
            className="text-emerald-600 hover:text-emerald-700 text-sm flex items-center"
          >
            See Thai Demo <ArrowRight className="w-4 h-4 ml-1" />
          </a>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
          <div className="flex items-center mb-4">
            <DollarSign className="w-6 h-6 text-emerald-600 mr-3 flex-shrink-0" />
            <h3 className="text-lg font-semibold text-gray-900">Business Impact</h3>
          </div>
          <p className="text-gray-600 mb-4">
            $23.7B lost annually due to failed payments, undelivered emails, and administrative corrections.
          </p>
          <Link
            to="/sales-pitch"
            className="text-emerald-600 hover:text-emerald-700 text-sm flex items-center"
          >
            Watch Sales Pitch <ArrowRight className="w-4 h-4 ml-1" />
          </Link>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
          <div className="flex items-center mb-4">
            <Database className="w-6 h-6 text-emerald-600 mr-3 flex-shrink-0" />
            <h3 className="text-lg font-semibold text-gray-900">Our Solution</h3>
          </div>
          <p className="text-gray-600 mb-4">
            World's largest database with 170M+ verified names and AI-powered processing.
          </p>
          <a
            href="https://nelma.mondonomo.ai"
            target="_blank"
            rel="noopener noreferrer"
            className="text-emerald-600 hover:text-emerald-700 text-sm flex items-center"
          >
            Explore NELMa Models <ArrowRight className="w-4 h-4 ml-1" />
          </a>
        </div>
      </div>
    </div>
  );
};

const SalesPitch = () => {
  return (
    <div className="container mx-auto px-4 py-8 flex flex-col items-center">
      <h1 className="text-2xl font-bold text-gray-900 mb-4">Sales Pitch</h1>
      <div className="w-full max-w-5xl aspect-video">
        <iframe
          className="w-full h-full"
          src="https://www.youtube.com/embed/T1Y_qDSSPfs?rel=0"
          title="Mondonomo Sales Pitch"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  );
};

export default App;